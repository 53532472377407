/*
 * Licensed under the EUPL, Version 1.2 or – as soon they will be approved by
 * the European Commission - subsequent versions of the EUPL (the "Licence");
 * You may not use this work except in compliance with the Licence.
 * You may obtain a copy of the Licence at:
 *
 *   https://joinup.ec.europa.eu/software/page/eupl
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Licence is distributed on an "AS IS" basis,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the Licence for the specific language governing permissions and
 * limitations under the Licence.
 *
 */

@import url(//fonts.googleapis.com/earlyaccess/notosanskannada.css);
@import "links.scss";

body {
  font-family: "Noto Sans Kannada", sans-serif;
  margin: 0;
  padding: 0;
}

.period-block:after {
  content: "";
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.page-link-parent {
  display: block;
  margin-bottom: 20px;
}

.page-link {
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 0.9em;
}

.active-link {
  color: #0d3c61;
  text-decoration: underline;
}

.inactive-link {
  color: #2196f3;
}

.jobstatus-wrapper {
  margin-bottom: 20px;
  border: 1px solid #eee;
  padding: 10px;
}

.version {
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 2000;
  color: white;
  font-size: 0.5em;
}

.footer {
  position: fixed;
  z-index: 2000;
  bottom: 20px;
  right: 16px;
}

.logo {
  height: 20px;
  width: auto;
}
